@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  background-color: #f0f2f5;
}
.custom_select_dropdown svg {
  display: unset;
}

.dashboard-section {
  scroll-margin-top: 80px;
}

.MuiAutocomplete-inputRoot {
  padding: 5px !important;
}

.swiper-pagination {
  position: unset !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #0e385e #f0f2f5;
  scroll-behavior: smooth;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 2px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #0e385e;
  border-radius: 10px;
  border: 0px none #ffffff;
}
